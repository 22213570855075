import EnvironmentTemplate from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/environment/EnvironmentTemplate';

import { AIR_QUALITY, USUAL_ENVIRONMENT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/OxidativeStress/Environment/EnvironmentTemplate',
  component: EnvironmentTemplate
};

const createStory = props => () => ({
  components: { EnvironmentTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><environment-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  localAirQuality: AIR_QUALITY.POLLUTED,
  usualEnvironment: [
    USUAL_ENVIRONMENT.INSIDE,
    USUAL_ENVIRONMENT.AIR_CONDITIONED,
    USUAL_ENVIRONMENT.IN_THE_SUN,
    USUAL_ENVIRONMENT.OUTSIDE
  ]
});

export const cleanLocalAirQualitySelected = createStory({
  localAirQuality: AIR_QUALITY.CLEAN,
  usualEnvironment: [USUAL_ENVIRONMENT.INSIDE]
});

export const unselected = createStory({
  localAirQuality: '',
  usualEnvironment: ''
});
